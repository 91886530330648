<template>
    <Layout :navigation="navigationObj">
        <router-view/>
    </Layout>
</template>

<script>
import Layout from "../components/Layout";

export default {
        name: "Posts",
        components: { Layout },
        data() {
            return {
                navigationObj: {
                    items: [
                        {
                            title: 'All Redeemables',
                            name: 'all-redeemables',
                            routeName: 'all-redeemables',
                            isSection: true
                        },
                        {
                            title: 'Add New Redeemable',
                            name: 'create-redeemable',
                            routeName: 'create-redeemable'
                        },
                        {
                            title: 'View Coupons',
                            name: 'all-coupons',
                            routeName: 'all-coupons',
                            isSection: true
                        },
                        {
                            title: 'Add Coupon',
                            name: 'create-coupon',
                            routeName: 'create-coupon'
                        },
                        {
                            title: "Litter PickUps",
                            name: "litter-pickups",
                            routeName: "litter-pickups",
                            isSection: true
                        },
                        {
                            title: "Litter Reports",
                            name: "litter-reports",
                            routeName: "litter-reports",
                            isSection: true
                        },
                         {
                            title: 'Litter Cleaned',
                            name: 'litter-cleaned',
                            routeName: 'litter-cleaned'
                        },
                        {
                            title: 'All Groups',
                            name: 'all-groups',
                            routeName: 'all-groups',
                            isSection: true
                        },
                        {
                            title: 'Add New Group',
                            name: 'create-group',
                            routeName: 'create-group'
                        },
                         {
                            title: 'Users Empact',
                            name: 'users-emapct',
                            routeName: 'users-emapct',
                            isSection: true
                        },
                    ]
                }
            }
        },
    }
</script>

<style scoped>

</style>
